import { TeacherList } from './TeacherList'
import { TeacherCreate } from './TeacherCreate'
import { TeacherShow } from './TeacherShow'
import { TeacherEdit } from './TeacherEdit'

export default {
  list: TeacherList,
  create: TeacherCreate,
  show: TeacherShow,
  edit: TeacherEdit
}

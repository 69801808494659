import { Create, SimpleForm, TextInput, required, SelectInput, ReferenceArrayInput, AutocompleteArrayInput, DateTimeInput, NumberInput, minLength } from 'react-admin'
import { Grid } from '@mui/material'
import { DefaultToolbar } from '@widgets/default-toolbar/DefaultToolbar'
import { PromocodeType } from '@entities/promocode/types/promocodeType'
import { PromocodePaymentType } from '@entities/promocode/types/promocodePaymentType'

export const PromocodeCreate = () => (
  <Create redirect="show">
    <SimpleForm toolbar={<DefaultToolbar />}>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <TextInput source="promo_code" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={8}>
          <ReferenceArrayInput source="packages" reference="product">
            <AutocompleteArrayInput optionText={(record: { title: string, amount: string }) => `${record.title} (${record.amount})`} validate={[required()]} fullWidth />
          </ReferenceArrayInput>
        </Grid>
        <Grid item md={4}>
          <SelectInput
            source="type"
            choices={Object.entries(PromocodeType).map(([name, id]) => ({
              id,
              name
            }))}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={4}>
          <NumberInput source="amount" label="Discount / Bonus" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <NumberInput source="activations" fullWidth />
        </Grid>
        <Grid item md={3}>
          <SelectInput
            source="status"
            choices={['enable', 'disable'].map((status) => ({
              id: status,
              name: status
            }))}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={3}>
          <SelectInput
            source="payment_type"
            choices={Object.entries(PromocodePaymentType).map(([name, id]) => ({
              id,
              name
            }))}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={3}>
          <DateTimeInput source="start_date.date" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={3}>
          <DateTimeInput source="finish_date.date" validate={[required()]} fullWidth />
        </Grid>
        {/* <Grid item md={4}>
        <SelectInput
          source="country"
          choices={Object.entries(Market).map(([name, id]) => ({
            id,
            name
          }))}
          validate={[required()]}
          fullWidth
        />
      </Grid> */}
        <Grid item md={12}>
          <TextInput source="description" validate={[required(), minLength(5)]} fullWidth multiline />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
)

import Hashids from 'hashids'

const SALT = 'smartclass'
const hashids = new Hashids(SALT)

export const encodeInviteLink = (b2bCompanyId: number) => {
  return [
    `https://${process.env.BACKEND as string}`,
    'login',
    hashids.encode(b2bCompanyId.toString()),
    'company'
  ].join('/')
}

import { Menu } from 'react-admin'
import AddCardIcon from '@mui/icons-material/AddCard'

export const CustomMenu = () => (
  <Menu>
    <Menu.ResourceItem name="user" />
    <Menu.ResourceItem name="student" />
    <Menu.ResourceItem name="teacher" />
    <Menu.ResourceItem name="company" />
    {process.env.PAYMENT_ADMIN_URL
      ? <Menu.Item to={process.env.PAYMENT_ADMIN_URL} primaryText="Payments" leftIcon={<AddCardIcon />} />
      : null}
  </Menu>
)

import { Datagrid, List, TextField, TextInput } from 'react-admin'

const filters = [
  <TextInput key="id" source="id" alwaysOn sx={{ width: 120 }} />,
  <TextInput key="email" source="email" alwaysOn />,
  <TextInput key="phone" source="phone" alwaysOn />,
  <TextInput key="title" source="title" label="Company name" alwaysOn />,
  <TextInput key="contract" source="contract" />,
  <TextInput key="contactPerson" source="contactPerson" />
]

export const CompanyList = () => {
  return (
    <List filters={filters} sort={{ field: 'id', order: 'DESC' }} exporter={false}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField label="Company Name" source="title" sortable={false} />
        <TextField source="country" sortable={false} />
        <TextField source="contract" sortable={false} />
        <TextField label="Employees" source="employees.length" sortable={false} />
        <TextField source="balance" sortable={false} />
      </Datagrid>
    </List>
  )
}

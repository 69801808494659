import md5 from 'md5'
import { Button } from '@mui/material'
import { useNotify, useRecordContext } from 'react-admin'
import { useCopyButton } from '@shared/hooks/useCopyButton'
import { useEffect, useRef } from 'react'
import { getAuthLink } from './api/getAuthLink'

export const QuickLessonLink = (props: {
  isRegistrationNotCompleted?: boolean
}) => {
  const record = useRecordContext()
  const notify = useNotify()
  const authLinkRef = useRef('')

  useEffect(() => {
    if (!record?.user?.id) return

    if (props.isRegistrationNotCompleted) {
      authLinkRef.current = `https://${process.env.BACKEND as string}/login/#signup`
      return
    }

    void getAuthLink({
      id: record.user.id,
      hash: md5(`${record.user.id as number}_${new Date().getTime()}_allright_pro`)
    }).then(hashResponse => {
      const link = `https://${process.env.BACKEND as string}/restore/${
        record.user.username as string
      }/${
        hashResponse?.data?.getAuthLink?.hash as string
      }`

      authLinkRef.current = link
    })
  }, [record?.user?.id, props.isRegistrationNotCompleted])

  const [label, handleClick] = useCopyButton(
    props.isRegistrationNotCompleted ? 'Sign Up Link' : 'Quick lesson link',
    () => {
      notify('Link copied to clipboard', { type: 'success' })
      void navigator.clipboard.writeText(authLinkRef.current)
    }
  )

  return (
    <Button variant="outlined" onClick={handleClick}>{label}</Button>
  )
}

import { AutocompleteInput, Datagrid, FunctionField, List, RadioButtonGroupInput, ReferenceInput, SelectInput, TextField, TextInput, minLength } from 'react-admin'
import { getLastLessonDate } from '@shared/helpers/getLastLessonDate'
import { LanguageCode } from '@shared/types/language'
import { TeacherGrade } from '@entities/teacher/types/teacherGrade'

const filters = [
  <TextInput key="id" source="id" alwaysOn />,
  <TextInput key="email" label="Email" source="username" alwaysOn />,
  <TextInput key="name" source="name" validate={[minLength(4)]} alwaysOn />,
  <AutocompleteInput
  key="languages"
  label="Language"
  source="languages"
  choices={Object.entries(LanguageCode).map(([name, id]) => ({
    id,
    name
  }))}
  />,
  <RadioButtonGroupInput key="methodist" source="methodist" choices={[
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' }
  ]} />,
  <TextInput key="userId" source="userId" label="User ID" />,
  <ReferenceInput key="course" source="course" reference="course">
    <SelectInput label="Course" optionText="titleEn" optionValue="titleEn" />
  </ReferenceInput>,
  <ReferenceInput label="Level students" key="levelStudent" source="levelStudent" reference="englishLevel">
    <SelectInput label="Level students" optionText="title" optionValue="title" disableValue="" />
  </ReferenceInput>,
  <SelectInput
    key="grade"
    source="grade"
    choices={Object.values(TeacherGrade).map((role) => ({
      id: role,
      name: role
    }))}
  />
]

export const TeacherList = () => {
  return (
    <List filters={filters} sort={{ field: 'id', order: 'DESC' }} exporter={false}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <FunctionField label="Native speaker" render={record => record.nativeSpeaker ? 'YES' : 'NO'} />
        <TextField source="user.username" label="Email" sortable={false} />
        <TextField source="phone" sortable={false} />
        <TextField source="languages" sortable={false} />
        <FunctionField label="Date last lesson" render={getLastLessonDate} />
        <FunctionField label="Methodist" render={record => record.methodist ? 'YES' : 'NO'} />
      </Datagrid>
    </List>
  )
}

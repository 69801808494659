export const UserRole = {
  ROLE_USER: 'ROLE_USER',
  ROLE_STUDENT_PAY: 'ROLE_STUDENT_PAY',
  ROLE_STUDENT_B2B: 'ROLE_STUDENT_B2B',
  ROLE_STUDENT_B2BC: 'ROLE_STUDENT_B2BC',
  ROLE_TEACHER_PAY: 'ROLE_TEACHER_PAY',
  ROLE_B2B_ADMIN: 'ROLE_B2B_ADMIN',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_FIN_MANAGER: 'ROLE_FIN_MANAGER'
} as const

export type UserRole = (typeof UserRole)[keyof typeof UserRole]

// Ролі користувачів у котрих є доступ в адмінку
export const ADMIN_ROLES = [
  UserRole.ROLE_ADMIN,
  UserRole.ROLE_MANAGER,
  UserRole.ROLE_FIN_MANAGER
] as UserRole[]

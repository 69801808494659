import { gql } from '@apollo/client'
import { apolloClient } from '@shared/api/apolloClient'

export const getAuthLink = async (data: { id: number, hash: string }) => await apolloClient.mutate({
  mutation: gql`
      mutation(
        $id: Int,
        $hash: String
      ) {
        getAuthLink(
          id: $id,
          hash: $hash,
        ) {
          hash
        }
      }
    `,
  variables: {
    ...data
  }
})

import { FunctionField, Show, TextField, useGetOne, useShowContext } from 'react-admin'
import { Box, Grid, Stack, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import getDiscount from './utils/getDiscount'

const ProductShowLayout = () => {
  const { record, isLoading } = useShowContext()
  const { data: baseProduct } = useGetOne('product', { id: 13 })

  if (isLoading) return

  return (
    <Box padding={2}>
      <Typography variant="h6" marginBottom={2}>
        Details
      </Typography>
      <Grid container spacing={2} marginBottom={4}>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">ID</Typography>
            <TextField source="id" />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Alias</Typography>
            <TextField source="alias" />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Amount</Typography>
            <TextField source="amount" />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Creation date</Typography>
            <FunctionField label="Creation date" render={(record) => new Date(record.dt_created.date).toLocaleDateString()} />
          </Stack>
        </Grid>
      </Grid>

      <Typography variant="h6" marginBottom={2}>
        Prices
      </Typography>
      <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Country</TableCell>
              <TableCell>Currency</TableCell>
              <TableCell>Сost per lesson</TableCell>
              <TableCell>Total cost</TableCell>
              <TableCell>Discount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {record._embedded?.prices?.map((price) => (
              <TableRow key={price.id}>
                <TableCell>{price.country}</TableCell>
                <TableCell>{price.currency}</TableCell>
                <TableCell>{price.price / record.amount}</TableCell>
                <TableCell>{price.price}</TableCell>
                <TableCell>{getDiscount(baseProduct, price, record.amount)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" marginBottom={2}>
        Localization
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Language</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>EN</TableCell>
              <TableCell>{record.title}</TableCell>
              <TableCell>{record.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PL</TableCell>
              <TableCell>{record.translations?.pl?.title}</TableCell>
              <TableCell>{record.translations?.pl?.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>RO</TableCell>
              <TableCell>{record.translations?.ro?.title}</TableCell>
              <TableCell>{record.translations?.ro?.description}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export const ProductShow = () => (
  <Show>
    <ProductShowLayout />
  </Show>
)

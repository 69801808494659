import { Edit, SimpleForm, TextInput, required, SelectInput, DateTimeInput, NumberInput, minLength } from 'react-admin'
import { Grid } from '@mui/material'
import { DefaultToolbar } from '@widgets/default-toolbar/DefaultToolbar'

export const PromocodeEdit = () => (
  <Edit redirect="show">
    <SimpleForm toolbar={<DefaultToolbar />}>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <NumberInput source="activations" fullWidth />
        </Grid>
        <Grid item md={3}>
          <SelectInput
            source="status"
            choices={['enable', 'disable'].map((status) => ({
              id: status,
              name: status
            }))}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={3}>
          <DateTimeInput source="start_date.date" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={3}>
          <DateTimeInput source="finish_date.date" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={12}>
          <TextInput source="description" validate={[required(), minLength(5)]} fullWidth multiline />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
)

import { SimpleForm, TextInput, AutocompleteInput, SelectInput, AutocompleteArrayInput, Edit, ReferenceArrayInput, SelectArrayInput, BooleanInput, NumberInput, DateInput, required, useRecordContext } from 'react-admin'
import { Grid, Typography } from '@mui/material'
import { DefaultToolbar } from '@widgets/default-toolbar/DefaultToolbar'
import { phone } from '@shared/validators/phone'
import { CountryCode } from '@shared/types/country'
import { LanguageCode } from '@shared/types/language'
import { TeacherGrade } from '@entities/teacher/types/teacherGrade'

const TeacherEditForm = () => {
  const record = useRecordContext()

  return (
    <SimpleForm toolbar={<DefaultToolbar />}>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <TextInput source="name" label="First name" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <TextInput source="lastName" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={4} alignSelf="center">
          <TextInput source="phone" validate={[required(), phone()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <DateInput label="Date of birth" source="birthday" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <AutocompleteInput
            source="country"
            choices={Object.entries(CountryCode).map(([name, id]) => ({
              id,
              name
            }))}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={4}>
          <AutocompleteArrayInput
            source="languages"
            choices={Object.entries(LanguageCode).map(([name, id]) => ({
              id,
              name
            }))}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={4} alignSelf="center">
          <BooleanInput source="nativeSpeaker" fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" marginTop={2} marginBottom={1}>
        Education
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <TextInput label="Education and certificate" source="education" validate={[required()]} fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" marginBottom={1}>
        Courses
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <ReferenceArrayInput source="courseIds" reference="course">
            <SelectArrayInput optionText="titleEn" validate={[required()]} fullWidth defaultValue={record.course?.map((i) => i.id)} variant="outlined" />
          </ReferenceArrayInput>
        </Grid>
        <Grid item md={4}>
          <ReferenceArrayInput source="levelStudentIds" reference="englishLevel">
            <SelectArrayInput label="Level students" optionText="title" validate={[required()]} fullWidth defaultValue={record.levelStudent?.map((i) => i.id)} variant="outlined" />
          </ReferenceArrayInput>
        </Grid>
        <Grid item md={4}>
          <NumberInput source="experience" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <SelectInput
            source="grade"
            choices={Object.values(TeacherGrade).map((role) => ({
              id: role,
              name: role
            }))}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={4} alignSelf="center">
          <BooleanInput source="methodist" fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" marginTop={2} marginBottom={1}>
        Notes
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <TextInput source="notes" fullWidth multiline />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

export const TeacherEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <TeacherEditForm />
  </Edit>
)

import { FunctionField, ReferenceArrayField, Show, SingleFieldList, TextField, useShowContext } from 'react-admin'
import { Box, Chip, Grid, Stack, Typography } from '@mui/material'
import { PromocodeLink } from '@features/promocode-link/PromocodeLink'

const PromocodeShowLayout = () => {
  const { record, isLoading } = useShowContext()

  if (isLoading) return

  return (
    <Box padding={2}>
      <Grid container spacing={2} marginBottom={3}>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Id</Typography>
            <TextField source="id" />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Code</Typography>
            <TextField source="promo_code" />
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Stack>
            <Typography variant="caption">Packages</Typography>
            <ReferenceArrayField label="Product (lesson pack)" reference="product" source="packages">
              <SingleFieldList>
              <FunctionField
                render={
                  (record: { title: string, amount: string }) => (<Chip sx={{ marginRight: '8px' }} label={`${record.title} (${record.amount})`} />)
                }
              />
              </SingleFieldList>
            </ReferenceArrayField>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2} marginBottom={3}>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Status</Typography>
            <TextField source="status" />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Type</Typography>
            <TextField source="type" />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Discount / Bonus</Typography>
            <TextField source="amount" />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Payment type</Typography>
            <TextField source="payment_type" />
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2} marginBottom={3}>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Creation date</Typography>
            <FunctionField render={(record) => new Date(record.dt_created.date).toLocaleDateString()} />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">Start date</Typography>
            <FunctionField render={(record) => record.start_date?.date ? new Date(record.start_date?.date).toLocaleString() : null} />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack>
            <Typography variant="caption">End date</Typography>
            <FunctionField render={(record) => record.finish_date?.date ? new Date(record.finish_date?.date).toLocaleString() : null} />
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={9}>
          <Stack>
            <Typography variant="caption">Description</Typography>
            <TextField source="description" />
          </Stack>
        </Grid>
        <Grid item md={3}>
          <Stack>
            <PromocodeLink promocode={record?.promo_code ?? ''} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export const PromocodeShow = () => (
  <Show>
    <PromocodeShowLayout />
  </Show>
)

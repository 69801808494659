import { combineDataProviders, type DataProvider } from 'react-admin'

import { userProvider } from '@entities/user/userProvider'
import { studentProvider } from '@entities/student/studentProvider'
import { teacherProvider } from '@entities/teacher/teacherProvider'
import { companyProvider } from '@entities/company/companyProvider'
import { englishLevelProvider } from '@entities/englishLevel/englishLevelProvider'
import { courseProvider } from '@entities/course/courseProvider'
// import { productProvider } from '@entities/product/productProvider'
// import { promocodeProvider } from '@entities/promocode/promocodeProvider'

const PROVIDERS: Record<string, DataProvider> = {
  user: userProvider,
  student: studentProvider,
  teacher: teacherProvider,
  company: companyProvider,
  englishLevel: englishLevelProvider,
  course: courseProvider
  // product: productProvider,
  // promocode: promocodeProvider
}

export const dataProvider = combineDataProviders((resource) => PROVIDERS[resource])

import { Grid } from '@mui/material'
import { Create, PasswordInput, SimpleForm, TextInput, email, required } from 'react-admin'

export const UserCreate = () => (
  <Create>
    <SimpleForm>
      <Grid container>
        <Grid item md={4}>
          <TextInput label="Email" source="username" validate={[required(), email()]} fullWidth />
          <PasswordInput source="password" validate={[required()]} fullWidth />
        </Grid>
      </Grid>

    </SimpleForm>
  </Create>
)

import { SimpleForm, TextInput, Edit, required } from 'react-admin'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { DefaultToolbar } from '@widgets/default-toolbar/DefaultToolbar'

const ProductEditForm = () => {
  return (
    <>
      {/* <Typography variant="h6" marginBottom={1}>
        Prices
      </Typography>
      <ArrayInput source="_embedded.prices" label="">
        <SimpleFormIterator inline disableAdd disableClear disableRemove disableReordering>
          <NumberInput source='id' sx={{ display: 'none' }} />
          <TextInput source="country" helperText={false} disabled />
          <TextInput source="currency" helperText={false} disabled />
          <NumberInput source="price" helperText={false} validate={[required()]} />
        </SimpleFormIterator>
      </ArrayInput> */}

      <Typography variant="h6" marginBottom={1}>
        Localization
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Language</TableCell>
              <TableCell>Title</TableCell>
              <TableCell width="40%">Description</TableCell>
              <TableCell>Speaking clubs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>EN</TableCell>
              <TableCell>
                <TextInput source="title" validate={[required()]} helperText={false} fullWidth />
              </TableCell>
              <TableCell>
                <TextInput source="description" validate={[required()]} helperText={false} fullWidth multiline />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PL</TableCell>
              <TableCell>
                <TextInput source="translations.pl.title" validate={[required()]} helperText={false} fullWidth />
              </TableCell>
              <TableCell>
                <TextInput source="translations.pl.description" validate={[required()]} helperText={false} fullWidth multiline />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>RO</TableCell>
              <TableCell>
                <TextInput source="translations.ro.title" validate={[required()]} helperText={false} fullWidth />
              </TableCell>
              <TableCell>
                <TextInput source="translations.ro.description" validate={[required()]} helperText={false} fullWidth multiline />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export const ProductEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm toolbar={<DefaultToolbar />}>
      <ProductEditForm />
    </SimpleForm>
  </Edit>
)

import { Edit, SimpleForm, TextInput, SelectArrayInput, required } from 'react-admin'
import { Grid } from '@mui/material'
import { DefaultToolbar } from '@widgets/default-toolbar/DefaultToolbar'
import { UserRole } from '@shared/types/userRole'

export const UserEdit = () => (
  <Edit mutationMode="pessimistic">
    <SimpleForm toolbar={<DefaultToolbar />}>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <TextInput disabled source="id" fullWidth />
        </Grid>
        <Grid item md={4}>
          <TextInput disabled source="username" fullWidth />
        </Grid>
        <Grid item md={4}>
          <SelectArrayInput
            source="roles"
            choices={Object.values(UserRole).map((role) => ({
              id: role,
              name: role
            }))}
            fullWidth
            validate={[required()]}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
)

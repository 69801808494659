import { type Validator } from 'react-admin'
import {
  parsePhoneNumber,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  validatePhoneNumberLength
} from 'libphonenumber-js/max'

const PHONE_ERRORS = {
  INVALID: 'Wrong number',
  INVALID_COUNTRY: 'This country code does not exist',
  TOO_SHORT: 'Number is too short',
  TOO_LONG: 'Number is too long',
  NOT_A_NUMBER: 'The number is not a phone number',
  INVALID_LENGTH: 'Wrong number'
}

export const phone = (): Validator => (value: string) => {
  try {
    const number = parsePhoneNumber(value.startsWith('+') ? value : `+${value}`)
    const isValid = isValidPhoneNumber(number.nationalNumber, number.country)
    const isPossible = isPossiblePhoneNumber(number.nationalNumber, number.country)
    const lengthError = validatePhoneNumberLength(number.nationalNumber, number.country)

    if (lengthError) return PHONE_ERRORS[lengthError]
    if (!isValid) return PHONE_ERRORS.INVALID_LENGTH
    if (!isPossible) return PHONE_ERRORS.TOO_LONG
  } catch (error: any) {
    return PHONE_ERRORS[error.message || 'INVALID']
  }
}

import { Create } from 'react-admin'
import { CompanyEditForm } from './components/CompanyEditForm'

export const CompanyCreate = () => {
  return (
    <Create redirect="show">
      <CompanyEditForm />
    </Create>
  )
}

import { Button } from '@mui/material'

import { useCopyButton } from '@shared/hooks/useCopyButton'

export type PromocodeLinkProps = {
  promocode: string
}

export const PromocodeLink = ({ promocode }: PromocodeLinkProps) => {
  const [copyRegistrationLinkLabel, copyRegistrationLink] = useCopyButton(
    'Copy promocode link',
    () => {
      const link = [
        `https://${process.env.BACKEND as string}`,
        'prices',
        promocode
      ].join('/')
      void navigator.clipboard.writeText(link)
    }
  )

  return (
    <Button variant="outlined" onClick={copyRegistrationLink}>
      {copyRegistrationLinkLabel}
    </Button>
  )
}

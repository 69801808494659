import { gql } from '@apollo/client'
import { type DataProvider } from 'react-admin'
import { apolloClient } from '@shared/api/apolloClient'

const STUDENT_FIELDS = `
  id
  name
  country
  city
  phone
  skype
  createdAt
  user {
    id
    username
    avatar {
      path
    }
    roles
  }
  balance
  languageCommunication
  notes
`

const DETAILED_STUDENT_FIELDS = `
  ${STUDENT_FIELDS}
  englishLevel {
    id
    title
  }
  company {
    contactPerson
    email
    id
    phone
    title
    studentCompanyId
  }
  studentLists {
    id
    teacher {
      id
      name
      languages
      user {
        username
      }
    }
    comment
    session {
      id
      lastLessonDate
      lessonsFinishedCount
      course {
        id
        titleEn
      }
    }
    schedule
    trial
  }
  lessonsLog {
    createdAt
    balance
    type
    description
    reporter {
      username
    }
  }
`

export const studentProvider: DataProvider = {
  getList: async (resource, { pagination, filter, sort }) => {
    return await apolloClient
      .query({
        query: gql`
          query ($page: Int, $itemsPerPage: Int, $order: StudentsOrder, $id: Int, $username: String, $phone: String, $country: [String], $name: String) {
            getStudents(page: $page, itemsPerPage: $itemsPerPage, order: $order, id: $id, username: $username, phone: $phone, country: $country, name: $name) {
              ${DETAILED_STUDENT_FIELDS}
            }
          }
        `,
        variables: {
          page: pagination.page,
          itemsPerPage: pagination.perPage,
          order: {
            [sort.field]: sort.order
          },
          id: +filter.id,
          username: filter.username,
          phone: filter.phone,
          country: filter.country,
          name: filter.name
        }
      })
      .then((result) => ({
        data: result.data.getStudents,
        pageInfo: {
          hasNextPage: result.data.getStudents.length === pagination.perPage,
          hasPreviousPage: pagination.page > 1
        }
      }))
  },
  getOne: async (resource, { id }) => {
    return await apolloClient
      .query({
        query: gql`
          query ($id: Int) {
            getStudents(id: $id) {
              ${DETAILED_STUDENT_FIELDS}
            }
          }
        `,
        variables: {
          id: +id
        }
      })
      .then((result) => ({
        data: result.data.getStudents[0]
      }))
  },
  getMany: async () => ({
    data: [] as any
  }),
  getManyReference: async () => ({
    data: [] as any,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false
    }
  }),
  create: async () => ({
    data: [] as any
  }),
  update: async (resource, { id, data, previousData }) => {
    const promises = [
      apolloClient.mutate({
        mutation: gql`
          mutation ($id: Int, $userData: StudentUpdateInput) {
            updateStudent(id: $id, userData: $userData) {
              ${STUDENT_FIELDS}
            }
          }
        `,
        variables: {
          id: +id,
          userData: {
            name: data.name,
            phone: data.phone,
            country: data.country,
            notes: data.notes,
            languageCommunication: data.languageCommunication,
            englishLevel: data.englishLevelId
          }
        }
      })
    ]

    if (data.company?.id && data.company?.id !== previousData.company?.id) {
      promises.push(
        apolloClient.mutate({
          mutation: gql`
            mutation ($id: Int, $companyId: Int) {
              bindStudentToCompany(b2bCompanyId: $companyId, studentId: $id) {
                status
              }
            }
          `,
          variables: {
            id: +id,
            companyId: data.company.id
          }
        })
      )
    }

    if (!data.company?.id && previousData.company?.id) {
      promises.push(
        apolloClient.mutate({
          mutation: gql`
            mutation ($studentCompanyId: Int) {
              removeStudentFromCompany(studentCompanyId: $studentCompanyId) {
                status
              }
            }
          `,
          variables: {
            studentCompanyId: previousData.company?.studentCompanyId
          }
        })
      )
    }

    previousData.studentLists?.forEach((studentList) => {
      if (!data.studentLists?.some((element) => element.id === studentList.id)) {
        promises.push(
          apolloClient
            .mutate({
              mutation: gql`
              mutation ($studentId: Int) {
                disconnectFromTeacher(studentId: $studentId) {
                  status
                }
              }
            `,
              variables: {
                studentId: studentList.id
              }
            })
        )
      }
    })

    data.studentLists?.forEach((studentList, i) => {
      if (studentList.id === undefined) {
        // Create
        promises.push(
          apolloClient.mutate({
            mutation: gql`
              mutation ($teacherId: Int, $studentId: Int, $courseId: Int, $schedule: String, $force: Int) {
                addStudentByInvite(teacherId: $teacherId, studentId: $studentId, courseId: $courseId, schedule: $schedule, force: $force) {
                  status
                }
              }
            `,
            variables: {
              teacherId: studentList.teacher.id,
              studentId: +id,
              courseId: studentList.session.course.id,
              schedule: studentList.schedule,
              force: 1
            }
          })
        )
      } else if (
        studentList.session.course.id !== previousData.studentLists[i].session.course.id ||
        studentList.teacher.id !== previousData.studentLists[i].teacher.id ||
        studentList.schedule !== previousData.studentLists[i].schedule
      ) {
        promises.push(
          apolloClient.mutate({
            mutation: gql`
              mutation ($studentListData: UpdateStudentListInput) {
                updateStudentList(studentListData: $studentListData) {
                  status
                }
              }
            `,
            variables: {
              studentListData: {
                listId: studentList.id,
                courseId: studentList.session.course.id,
                teacherId: studentList.teacher.id,
                schedule: studentList.schedule
              }
            }
          })
        )
      }
    })

    const [result] = await Promise.all(promises)

    return {
      data: {
        ...result.data.updateStudent,
        id: +id
      }
    }
  },
  updateMany: async () => ({
    data: [] as any
  }),
  delete: async () => ({
    data: [] as any
  }),
  deleteMany: async () => ({
    data: [] as any
  })
}

import { gql } from '@apollo/client'
import { type DataProvider } from 'react-admin'
import { apolloClient } from '@shared/api/apolloClient'

const PACKAGE_FIELDS = `
  id
  title
  alias
  amount
  package_type
  description
  status
  dt_created {
    date
  }
  _embedded {
    prices {
      id
      package_id
      currency
      price
      country
    }
  }
  translations {
    pl {
      title
      description
    }
    ro {
      title
      description
    }
  }
`

const PACKAGE_RESPONSE_FIELDS = `
  _total_items
  _page
  _page_count
  data {
    ${PACKAGE_FIELDS}
  }
`

export const productProvider: DataProvider = {
  getList: async (resource, { pagination, filter, sort }) => {
    return await apolloClient
      .query({
        query: gql`
          query($page: Int, $limit: Int) {
            getPackages(page: $page, limit: $limit) {
              ${PACKAGE_RESPONSE_FIELDS}
            }
          }
        `,
        variables: {
          page: pagination.page,
          limit: pagination.perPage
        }
      })
      .then((result) => ({
        data: result.data.getPackages?.data ?? [],
        pageInfo: {
          hasNextPage: result.data.getPackages?._page < result.data.getPackages?._page_count,
          hasPreviousPage: result.data.getPackages?._page > 1
        }
      }))
  },
  getOne: async (resource, { id }) => {
    return await apolloClient
      .query({
        query: gql`
          query($id: Int) {
            getPackage(id: $id) {
              ${PACKAGE_FIELDS}
            }
          }
        `,
        variables: {
          id: +id
        }
      })
      .then((result) => ({
        data: result.data.getPackage
      }))
  },
  getMany: async () => {
    return await apolloClient
      .query({
        query: gql`
          query {
            getPackages {
              ${PACKAGE_RESPONSE_FIELDS}
            }
          }
        `
      })
      .then((result) => ({
        data: result.data.getPackages?.data ?? []
      }))
  },
  getManyReference: async () => ({
    data: [] as any,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false
    }
  }),
  create: async () => ({
    data: {} as any
  }),
  update: async (resource, { id, data, previousData }) => {
    const promises = [
      apolloClient.mutate({
        mutation: gql`
          mutation ($id: Int, $packageData: PackageUpdateInput) {
            updatePackage(id: $id, packageData: $packageData) {
              ${PACKAGE_FIELDS}
            }
          }
        `,
        variables: {
          id: +id,
          packageData: {
            title: data.title,
            description: data.description,
            translations: {
              pl: {
                title: data.translations.pl.title,
                description: data.translations.pl.description
              },
              ro: {
                title: data.translations.ro.title,
                description: data.translations.ro.description
              }
            }
          }
        }
      })
    ]

    // data._embedded.prices.forEach((price, i) => {
    //   const previousPrice = previousData._embedded.prices[i]

    //   if (price.price !== previousPrice.price) {
    //     promises.push(
    //       apolloClient.mutate({
    //         mutation: gql`
    //           mutation ($id: Int, $price: Int) {
    //             updatePrice(id: $id, price: $price) {
    //               id
    //             }
    //           }
    //         `,
    //         variables: {
    //           id: price.id,
    //           price: price.price
    //         }
    //       })
    //     )
    //   }
    // })

    const [result] = await Promise.all(promises)

    return {
      data: {
        ...result.data.updatePackage,
        id: +id
      }
    }
  },
  updateMany: async () => ({
    data: [] as any
  }),
  delete: async () => ({
    data: [] as any
  }),
  deleteMany: async () => ({
    data: [] as any
  })
}

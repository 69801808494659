import { Datagrid, FunctionField, List, TextField } from 'react-admin'
import { getPrice } from './utils/getPrice'

export const ProductList = () => (
  <List exporter={false}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="id" sortable={false} />
      <TextField source="title" sortable={false} />
      <TextField source="alias" sortable={false} />
      <TextField source="amount" label="Lesson package" sortable={false} />
      <FunctionField label="Сost per lesson" render={(record) => `${getPrice(record) / record.amount} eur`} />
      <FunctionField label="Total cost" render={(record) => `${getPrice(record)} eur`} />
      <FunctionField label="Creation date" render={(record) => new Date(record.dt_created.date).toLocaleDateString()} />
    </Datagrid>
  </List>
)

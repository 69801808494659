import './autobahn'

let isConnected = false
let wssClient: any = null

const ACTION_CALLBACKS = {}

const getUserId = () => {
  const user: string | undefined = localStorage.getItem('user') ?? ''

  if (user) {
    try {
      const data = JSON.parse(user)

      return data.id
    } catch (e) {
      return null
    }
  }

  return null
}

const getSessionId = () => {
  const id = getUserId()

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  if (id) return `${id}_admin`

  return null
}

const publish = (data: any) => {
  const sessionId = getSessionId()

  // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/prefer-optional-chain
  if (wssClient && wssClient._websocket_connected && typeof window !== 'undefined') {
    if (sessionId) {
      ACTION_CALLBACKS[data.cb.id] = data.cb.action
      wssClient.publish(data.data, { sessionId })
    }
  }
}

const wssSubscribe = () => {
  const sessionId = getSessionId()

  // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/prefer-optional-chain
  if (wssClient && wssClient._websocket_connected && sessionId) {
    wssClient.subscribe(sessionId, (_: any, data: { action: string, data: any }) => {
      switch (data.action) {
        case 'admin-connection-check-response':
          if (data.data?.cbId) ACTION_CALLBACKS[data.data?.cbId](data.data)
          break
        default:
          break
      }
    })
  }
}

const init = () => {
  const sessionId = getSessionId()

  if (typeof window !== 'undefined' && sessionId) {
    wssClient = new ab.Session(
      `wss://${window.location.host.replace('admin.', '').replace('admin-', '')}/node-wss/`,
      () => {
        isConnected = true
        wssSubscribe()
      },
      (code: number) => {
        isConnected = false
      }
    )
  }
}

const cleanCb = (id: string) => {
  ACTION_CALLBACKS[id] = null
}

const close = () => {
  // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/prefer-optional-chain
  if (wssClient && wssClient._websocket_connected) {
    isConnected = false
    wssClient.close()
  }
}

export default {
  cleanCb,
  close,
  getIsConnected: () => isConnected,
  init,
  publish
}

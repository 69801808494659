import { gql } from '@apollo/client'
import { type DataProvider } from 'react-admin'
import { apolloClient } from '@shared/api/apolloClient'

const COMPANY_FIELDS = `
  id
  title
  contactPerson
  email
  phone
  createdAt
  contract
  notes
  languageCommunication
  country
  balance
`

const DETAILED_COMPANY_FIELDS = `
  ${COMPANY_FIELDS}
  employees {
    id
    name
    phone
    user {
      username
      avatar {
        path
      }
    }
    balance
    studentLists {
      id
      session {
        lastLessonDate
      }
    }
  }
`

export const companyProvider: DataProvider = {
  getList: async (resource, { pagination, filter, sort }) => {
    return await apolloClient
      .query({
        query: gql`
          query ($page: Int, $itemsPerPage: Int, $order: B2BCompanyOrder, $id: Int, $email: String, $phone: String, $contract: String, $title: String, $contactPerson: String) {
            getB2BCompanies(page: $page, itemsPerPage: $itemsPerPage, order: $order, id: $id, email: $email, phone: $phone, contract: $contract, title: $title, contactPerson: $contactPerson) {
              ${DETAILED_COMPANY_FIELDS}
            }
          }
        `,
        variables: {
          page: pagination.page,
          itemsPerPage: pagination.perPage,
          order: {
            [sort.field]: sort.order
          },
          id: +filter.id,
          email: filter.email,
          phone: filter.phone,
          contract: filter.contract,
          title: filter.title,
          contactPerson: filter.contactPerson
        }
      })
      .then((result) => {
        return {
          data: result.data.getB2BCompanies,
          pageInfo: {
            hasNextPage: result.data.getB2BCompanies.length === pagination.perPage,
            hasPreviousPage: pagination.page > 1
          }
        }
      })
  },
  getOne: async (resource, { id }) => {
    return await apolloClient
      .query({
        query: gql`
          query ($id: Int) {
            getB2BCompanies(id: $id) {
              ${DETAILED_COMPANY_FIELDS}
            }
          }
        `,
        variables: {
          id: +id
        }
      })
      .then((result) => ({
        data: result.data.getB2BCompanies[0]
      }))
  },
  getMany: async () => ({
    data: [] as any
  }),
  getManyReference: async () => ({
    data: [] as any,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false
    }
  }),
  create: async (resource, { data }) => {
    return await apolloClient
      .mutate({
        mutation: gql`
          mutation ($companyData: B2BCompanyInput) {
            addB2BCompany(companyData: $companyData) {
              ${COMPANY_FIELDS}
            }
          }
        `,
        variables: {
          companyData: {
            title: data.title,
            contract: data.contract,
            contactPerson: data.contactPerson,
            email: data.email,
            phone: data.phone,
            notes: data.notes,
            country: data.country,
            languageCommunication: data.languageCommunication
          }
        }
      })
      .then((result) => ({
        data: result.data.addB2BCompany
      }))
  },
  update: async (resource, { id, data }) => {
    return await apolloClient
      .mutate({
        mutation: gql`
          mutation ($id: Int, $companyData: B2BCompanyInput) {
            updateB2BCompany(id: $id, companyData: $companyData) {
              ${COMPANY_FIELDS}
            }
          }
        `,
        variables: {
          id: +id,
          companyData: {
            title: data.title,
            contract: data.contract,
            contactPerson: data.contactPerson,
            email: data.email,
            phone: data.phone,
            notes: data.notes,
            country: data.country,
            languageCommunication: data.languageCommunication
          }
        }
      })
      .then((result) => ({
        data: {
          ...result.data.updateB2BCompany,
          id: +id
        }
      }))
  },
  updateMany: async () => ({
    data: [] as any
  }),
  delete: async () => ({
    data: [] as any
  }),
  deleteMany: async () => ({
    data: [] as any
  })
}

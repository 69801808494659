import { gql } from '@apollo/client'
import { type DataProvider } from 'react-admin'
import { apolloClient } from '@shared/api/apolloClient'

export const courseProvider: DataProvider = {
  getList: async (resource, params) => {
    return await apolloClient
      .query({
        query: gql`
          query {
            getCoursesList {
              id
              titleEn
            }
          }
        `
      })
      .then((result) => ({
        data: result.data.getCoursesList,
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false
        }
      }))
  },
  getOne: async (resource, { id }) => ({
    data: { id } as any
  }),
  getMany: async () => ({
    data: [] as any
  }),
  getManyReference: async () => ({
    data: [] as any,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false
    }
  }),
  create: async () => ({
    data: [] as any
  }),
  update: async () => ({
    data: [] as any
  }),
  updateMany: async () => ({
    data: [] as any
  }),
  delete: async () => ({
    data: [] as any
  }),
  deleteMany: async () => ({
    data: [] as any
  })
}

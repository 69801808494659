import {
  ApolloClient, createHttpLink, InMemoryCache, ApolloLink, fromPromise, type DefaultContext
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import clientId from './utils/clientId'
import loginUtils from '@shared/auth/utils'
import { siteAuthRefresh } from '@shared/auth/api'

clientId.init()

const link = createHttpLink({
  uri: '/api/'
})

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: DefaultContext) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${loginUtils.getAccessToken()}`,
      'X-Client-Id': clientId.get() ?? ''
    }
  }))
  return forward(operation)
})

const tokenRefresh = async () => {
  loginUtils.deleteToken(false)

  const response = await siteAuthRefresh()

  const tokenData = response.data.tokenRefresh

  if (tokenData.access_token !== undefined) {
    loginUtils.saveToken(tokenData)
  }
}

const errorLink = onError(
  ({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors?.[0].message === 'access_token_invalid') {
      return fromPromise(tokenRefresh()).flatMap(() => forward(operation))
    }
  }
)

const changeErrorMessageLink = onError(
  ({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors?.length) {
      return forward(operation).map(response => {
        if (response.errors) {
          // @ts-expect-error change error messages
          response.errors = response.errors.map(err => new Error(err?.extensions?.detail as string ?? err.message))
        }
        return response
      })
    }
  }
)

export const apolloClient = new ApolloClient({
  queryDeduplication: false,
  link: ApolloLink.from([
    errorLink,
    authLink,
    changeErrorMessageLink,
    link
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  },
  connectToDevTools: true
})

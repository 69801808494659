import { Edit, SimpleForm, TextInput, SelectInput, required, AutocompleteInput, ArrayInput, SimpleFormIterator, useRecordContext, FormDataConsumer, ReferenceArrayInput, NumberInput, ReferenceInput } from 'react-admin'
import { Grid, Typography } from '@mui/material'
import { DefaultToolbar } from '@widgets/default-toolbar/DefaultToolbar'
import { phone } from '@shared/validators/phone'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { CountryCode } from '@shared/types/country'
import { LanguageCode } from '@shared/types/language'

const StudentEditForm = () => {
  const record = useRecordContext()

  return (
    <>
      <Typography variant="h6" marginBottom={1}>
        Identity
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <TextInput source="name" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <TextInput type="phone" source="phone" validate={[required(), phone()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <ReferenceArrayInput source="englishLevelId" reference="englishLevel">
            <SelectInput label="English level" optionText="title" fullWidth defaultValue={record.englishLevel?.id} />
          </ReferenceArrayInput>
        </Grid>
      </Grid>

      <Typography variant="h6" marginTop={2} marginBottom={1}>
        Locale
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <AutocompleteInput
            source="country"
            label="Country"
            choices={Object.entries(CountryCode).map(([name, id]) => ({
              id,
              name
            }))}
            fullWidth
            validate={[required()]}
          />
        </Grid>
        <Grid item md={4}>
          <AutocompleteInput
            source="languageCommunication"
            label="Language"
            choices={Object.entries(LanguageCode).map(([name, id]) => ({
              id,
              name
            }))}
            fullWidth
          />
        </Grid>
        <Grid item md={4}>
          <ReferenceArrayInput source="company.id" reference="company" perPage={999}>
            <SelectInput
              label="Company"
              optionText={company => `${company.title as string} (${company.id as number})`}
              fullWidth
            />
          </ReferenceArrayInput>

          {/* TODO: Попросити додати в api пошук компаній по назві та замінити селект на автокомпліт */}
          {/* <ReferenceInput label="Company" source="company.id" reference="company">
            <AutocompleteInput debounce={500} optionText={(company) => company.title} />
          </ReferenceInput> */}
        </Grid>
      </Grid>

      <Typography variant="h6" marginTop={2} marginBottom={1}>
        Courses
      </Typography>
      <ArrayInput source="studentLists" label={false}>
        <SimpleFormIterator
          fullWidth disableClear disableReordering
          removeButton={<HighlightOffIcon color="error" sx={{ marginTop: 1.4, marginLeft: 1, cursor: 'pointer' }} titleAccess={'Disconnect from the teacher'} />}>
          <FormDataConsumer>
            {({
              formData, // The whole form data
              scopedFormData, // The data for this item of the ArrayInput
              getSource, // A function to get the valid source inside an ArrayInput
              ...rest
            }) => (
              <Grid container spacing={2}>
                {/* Костиль з інпутом з display: 'none' додає поля до update request'у */}
                <TextInput source={getSource?.('id') ?? ''} sx={{ display: 'none' }} />
                <NumberInput source={getSource?.('teacher.id') ?? ''} sx={{ display: 'none' }} />
                <NumberInput source={getSource?.('company.studentCompanyId') ?? ''} sx={{ display: 'none' }} />
                <Grid item md={3}>
                  <ReferenceInput source={getSource?.('teacher.id') ?? ''} reference="teacher">
                    <AutocompleteInput label="Teacher" debounce={500} optionText="name" helperText={false} validate={[required()]} />
                  </ReferenceInput>
                </Grid>
                <Grid item md={4}>
                  <ReferenceArrayInput source={getSource?.('session.course.id') ?? ''} reference="course">
                    <SelectInput label="Courses" optionText="titleEn" validate={[required()]} fullWidth />
                  </ReferenceArrayInput>
                </Grid>
                <Grid item md={5}>
                  <TextInput source={getSource?.('schedule') ?? ''} fullWidth helperText={false} />
                </Grid>
              </Grid>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>

      <Typography variant="h6" marginTop={2} marginBottom={1}>
        Notes
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <TextInput label="Notes" source="notes" fullWidth multiline />
        </Grid>
      </Grid>
    </>
  )
}
export const StudentEdit = () => (
  <Edit mutationMode="pessimistic" redirect="show">
    <SimpleForm toolbar={<DefaultToolbar />}>
      <StudentEditForm />
    </SimpleForm>
  </Edit>
)

import { FunctionField, Link, Show, TabbedShowLayout, TextField, useShowContext } from 'react-admin'
import { Avatar, Box, Chip, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

const TeacherShowLayout = () => {
  const { record, isLoading } = useShowContext()

  if (isLoading) return

  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid item md={2}>
          <Avatar sx={{ aspectRatio: '1 / 1', width: '100%', height: 'auto' }} src={record.user?.avatar?.at(-1)?.path} variant="square" />
        </Grid>
        <Grid item md={10}>
          <Stack>
            <Typography variant="h6" marginBottom={2}>
              {record.name} {record.lastName}
              {record.nativeSpeaker ? <Chip label={'Native'} sx={{ marginLeft: 1 }} /> : null}
            </Typography>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">ID</Typography>
                  <TextField source="id" />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Birthday</Typography>
                  <TextField source="birthday" />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Languages</Typography>
                  <FunctionField render={(record) => record.languages?.join(', ')} />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Email</Typography>
                  <TextField source="user.username" />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Country</Typography>
                  <TextField source="country" />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Create Date</Typography>
                  <FunctionField label="Create Date" render={(record) => new Date(record.createdAt).toLocaleDateString()} />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Phone</Typography>
                  <TextField source="phone" />
                </Stack>
              </Grid>
              <Grid item md={4}></Grid>
              <Grid item md={4}></Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <Grid container marginTop={4} spacing={2}>
        <Grid item md={4}>
          <Stack>
            <Typography variant="caption">Education</Typography>
            <TextField source="education" />
          </Stack>
        </Grid>
        <Grid item md={4}>
          <Stack>
            <Typography variant="caption">Courses</Typography>
            <FunctionField render={(record) => record.course?.map(i => i.titleEn).join(', ')} />
          </Stack>
        </Grid>
        <Grid item md={4}>
          <Stack>
            <Typography variant="caption">Level students</Typography>
            <FunctionField render={(record) => record.levelStudent?.map(i => i.title).join(', ')} />
          </Stack>
        </Grid>
        <Grid item md={4}>
          <Stack>
            <Typography variant="caption">Experience</Typography>
            <TextField source="experience" />
          </Stack>
        </Grid>
        <Grid item md={4}>
          <Stack>
            <Typography variant="caption">Grade</Typography>
            <TextField source="grade" />
          </Stack>
        </Grid>
        <Grid item md={4}>
          <Stack>
            <Typography variant="caption">Methodist</Typography>
            <FunctionField render={(record) => (record.methodist ? 'YES' : 'NO')} />
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ marginTop: 4 }} />

      <TabbedShowLayout sx={{ marginTop: 4 }}>
        <TabbedShowLayout.Tab label="Notes">
          <Typography variant="h6">Notes about the teacher</Typography>
          <Box sx={{ borderRadius: 1, border: 1, borderColor: 'grey.600', padding: 1 }}>
            <TextField source="notes" />
          </Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Students">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Student</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Schedule</TableCell>
                  <TableCell>Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.studentLists?.map((studentList) => (
                  <TableRow key={studentList.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/student/${studentList.session?.student?.id as number}/show`} sx={{ color: 'currentColor' }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <Avatar src={studentList?.session?.student?.user?.avatar?.at(-1)?.path} />
                          <Box>
                            <Typography variant="body1">{studentList.session?.student?.name}</Typography>
                            <Typography variant="body2">ID {studentList.session?.student?.id}</Typography>
                          </Box>
                        </Box>
                      </Link>
                    </TableCell>
                    <TableCell>{studentList.session?.student?.user?.username}</TableCell>
                    <TableCell>{studentList.schedule}</TableCell>
                    <TableCell>{studentList.session?.student?.balance}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Box>
  )
}

export const TeacherShow = () => (
  <Show>
    <TeacherShowLayout />
  </Show>
)

import { gql } from '@apollo/client'
import { type DataProvider } from 'react-admin'
import { apolloClient } from '@shared/api/apolloClient'

const TEACHER_FIELDS = `
  id
  name
  skype
  phone
  user {
    username
  }
  birthday
  createdAt
  lastName
  nativeSpeaker
  country
  languages
  education
  experience
  grade
  methodist
  notes
`

const DETAILED_TEACHER_FIELDS = `
  ${TEACHER_FIELDS}
  course {
    id
    titleEn
  }
  levelStudent {
    id
    title
  }
  studentLists {
    id
    session {
      lastLessonDate
      student {
        id
        name
        user {
          username
          avatar {
            path
          }
        }
        balance
      }
    }
    schedule
  }
`

export const teacherProvider: DataProvider = {
  getList: async (resource, { pagination, filter, sort }) => {
    return await apolloClient
      .query({
        query: gql`
          query ($page: Int, $itemsPerPage: Int, $order: TeacherOrder, $id: Int, $username: String, $name: String, $languages: String, $methodist: String, $userId: Int, $course: String, $levelStudent: String, $grade: String) {
            getTeachers(page: $page, itemsPerPage: $itemsPerPage, order: $order, id: $id, username: $username, name: $name, languages: $languages, methodist: $methodist, userId: $userId, course: $course, levelStudent: $levelStudent, grade: $grade) {
              ${DETAILED_TEACHER_FIELDS}
            }
          }
        `,
        variables: {
          page: pagination.page,
          itemsPerPage: pagination.perPage,
          order: {
            [sort.field]: sort.order
          },
          id: +filter.id,
          username: filter.username,
          name: filter.name ?? filter.q,
          languages: filter.languages,
          methodist: filter.methodist,
          userId: +filter.userId,
          course: filter.course,
          levelStudent: filter.levelStudent,
          grade: filter.grade
        }
      })
      .then((result) => ({
        data: result.data.getTeachers,
        pageInfo: {
          hasNextPage: result.data.getTeachers.length === pagination.perPage,
          hasPreviousPage: pagination.page > 1
        }
      }))
  },
  getOne: async (resource, { id }) => {
    return await apolloClient
      .query({
        query: gql`
          query ($id: Int) {
            getTeachers(id: $id) {
              ${DETAILED_TEACHER_FIELDS}
            }
          }
        `,
        variables: {
          id: +id
        }
      })
      .then((result) => ({
        data: result.data.getTeachers[0]
      }))
  },
  getMany: async (resource, { ids }) => {
    return await apolloClient
      .query({
        query: gql`
        query ($ids: [Int]) {
          getTeachers(ids: $ids) {
            ${DETAILED_TEACHER_FIELDS}
          }
        }
      `,
        variables: {
          ids
        }
      })
      .then((result) => ({
        data: result.data.getTeachers
      }))
  },
  getManyReference: async () => ({
    data: [] as any,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false
    }
  }),
  create: async (resource, { data }) => {
    const createdUser = await apolloClient
      .mutate({
        mutation: gql`
          mutation ($username: String!, $password: String!) {
            createUser(username: $username, password: $password) {
              id
              username
              roles
              createdAt
            }
          }
        `,
        variables: {
          username: data.username,
          password: data.password
        }
      })
      .then((result) => ({
        data: result.data.createUser
      }))

    return await apolloClient
      .mutate({
        mutation: gql`
          mutation ($id: Int, $userData: TeacherInput) {
            addTeacher(id: $id, userData: $userData) {
              ${TEACHER_FIELDS}
            }
          }
        `,
        variables: {
          id: createdUser.data.id,
          userData: {
            name: data.name,
            lastName: data.lastName,
            nativeSpeaker: data.nativeSpeaker,
            phone: data.phone,
            birthday: data.birthday,
            country: data.country,
            languages: data.languages,
            education: data.education,
            course: data.course,
            levelStudent: data.levelStudent,
            experience: data.experience.toString(),
            grade: data.grade,
            methodist: data.methodist,
            notes: data.notes
          }
        }
      })
      .then((result) => ({
        data: result.data.addTeacher
      }))
  },
  update: async (resource, { id, data }) => {
    return await apolloClient
      .mutate({
        mutation: gql`
          mutation ($id: Int, $userData: TeacherInput) {
            updateTeacher(id: $id, userData: $userData) {
              ${TEACHER_FIELDS}
            }
          }
        `,
        variables: {
          id: +id,
          userData: {
            name: data.name,
            lastName: data.lastName,
            nativeSpeaker: data.nativeSpeaker,
            phone: data.phone,
            birthday: data.birthday,
            country: data.country,
            languages: data.languages,
            education: data.education,
            course: data.courseIds,
            levelStudent: data.levelStudentIds,
            experience: data.experience.toString(),
            grade: data.grade,
            methodist: data.methodist,
            notes: data.notes
          }
        }
      })
      .then((result) => ({
        data: result.data.updateTeacher
      }))
  },
  updateMany: async () => ({
    data: [] as any
  }),
  delete: async () => ({
    data: [] as any
  }),
  deleteMany: async () => ({
    data: [] as any
  })
}

import { Buffer } from 'buffer'
import Cookies from 'js-cookie'
import type { Token } from './types'
import { UserRole } from '@shared/types/userRole'

const getRootDomain = () => {
  const { hostname } = window.location

  const parts = hostname.split('.')

  if (parts.length === 2) {
    return hostname
  }

  return parts.slice(-2).join('.')
}

const getAccessToken = () => {
  const token: string | undefined = Cookies.get('accessToken')

  return token ?? ''
}

const getRefreshToken = () => Cookies.get('refreshToken') ?? ''

const findRole = (roles: UserRole[]) => {
  let role: string = ''

  roles.forEach((el: UserRole) => {
    if (el === 'ROLE_B2B_ADMIN') {
      role = UserRole[el]
    }

    if (el === 'ROLE_ADMIN') {
      role = UserRole[el]
    }

    if (role.length === 0) role = UserRole[el]
  })

  return role
}

const convertToken = (token: string) => {
  const base64Url = token.split('.')[1]

  if (base64Url.length > 0) {
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    const decoded = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'))

    return decoded
  }

  return {}
}

const parseToken = (token: string) => {
  const decoded = convertToken(token)

  return {
    role: decoded.roles.length > 0 ? findRole(decoded.roles) : '',
    uid: decoded.uid
  }
}

const saveToken = (data: Token) => {
  Cookies.set('accessToken', data.access_token, {
    path: '/'
  })
  Cookies.set('refreshToken', data.refresh_token, {
    path: '/'
  })
  Cookies.set(`ap_${process.env.ENVIRONMENT ?? 'local'}_payment_jwt`, data.access_token, { expires: 365, path: '/', domain: `.${getRootDomain()}` })
}

const deleteToken = (toRemoveRefresh: boolean) => {
  Cookies.remove('accessToken', { path: '/' })

  if (toRemoveRefresh) Cookies.remove('refreshToken', { path: '/' })
}

export default {
  getAccessToken,
  getRefreshToken,
  findRole,
  saveToken,
  parseToken,
  convertToken,
  deleteToken
}

import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { Form, NumberInput, SaveButton, SelectInput, TextInput, required, useNotify, useRefresh } from 'react-admin'
import { PaymentType } from '@shared/types/paymentType'
import { addStudentBalanceLog } from './api/addStudentBalanceLog'

export function CountTheLessons () {
  const refresh = useRefresh()
  const notify = useNotify()

  const [open, setOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = (data, event) => {
    setIsSending(true)
    void addStudentBalanceLog(data).then(() => {
      notify('Number of lessons updated', { type: 'success' })
    })
      .catch((error) => {
        notify(error.networkError.result.errors[0].message ?? 'Something went wrong', { type: 'error' })
      })
      .finally(() => {
        setIsSending(false)
        setOpen(false)
        refresh()
      })
  }

  return (
      <>
      <Button variant="contained" onClick={handleClickOpen}>
        Update number of lessons
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <NumberInput source="lessonsCount" label="Number of lessons" validate={[required()]} min={1} max={50} fullWidth autoFocus />
            <SelectInput
              source="type"
              choices={Object.values(PaymentType).map((role) => ({
                id: role,
                name: role
              }))}
              validate={[required()]}
              fullWidth
            />
            <TextInput source="description" label="Comment" validate={[required()]} fullWidth />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <SaveButton label="Submit" disabled={isSending} />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}

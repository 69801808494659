import { gql } from '@apollo/client'
import { type DataProvider } from 'react-admin'
import { apolloClient } from '@shared/api/apolloClient'

const USER_FIELDS = `
  id
  username
  roles
  createdAt
`

export const userProvider: DataProvider = {
  getList: async (resource, { pagination, filter, sort }) => {
    return await apolloClient
      .query({
        query: gql`
          query ($email: String, $roles: [String], $page: Int, $itemsPerPage: Int, $order: UserOrder) {
            getUsers(email: $email, roles: $roles, page: $page, itemsPerPage: $itemsPerPage, order: $order) {
              ${USER_FIELDS}
            }
          }
        `,
        variables: {
          email: filter.email,
          roles: filter.roles,
          page: pagination.page,
          itemsPerPage: pagination.perPage,
          order: {
            [sort.field]: sort.order
          }
        }
      })
      .then((result) => ({
        data: result.data.getUsers,
        pageInfo: {
          // TODO: попросити бекендерів віддавати інформацію про кількість сторінок та позбавитись від цього костиля на фронті
          hasNextPage: result.data.getUsers.length === pagination.perPage,
          hasPreviousPage: pagination.page > 1
        }
      }))
  },
  getOne: async (resource, { id }) => {
    return await apolloClient
      .query({
        query: gql`
          query ($id: Int) {
            getUser(id: $id) {
              ${USER_FIELDS}
            }
          }
        `,
        variables: {
          id: +id
        }
      })
      .then((result) => ({
        data: result.data.getUser
      }))
  },
  getMany: async () => ({
    data: [] as any
  }),
  getManyReference: async () => ({
    data: [] as any,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false
    }
  }),
  create: async (resource, { data }) => {
    return await apolloClient
      .mutate({
        mutation: gql`
          mutation ($username: String!, $password: String!) {
            createUser(username: $username, password: $password) {
              ${USER_FIELDS}
            }
          }
        `,
        variables: {
          username: data.username,
          password: data.password
        }
      })
      .then((result) => ({
        data: result.data.createUser
      }))
  },
  update: async (resource, { data }) => {
    return await apolloClient
      .mutate({
        mutation: gql`
          mutation ($id: Int!, $roles: [String]) {
            updateUser(id: $id, roles: $roles) {
              status
            }
          }
        `,
        variables: {
          id: data.id,
          roles: data.roles
        }
      })
      .then(() => ({
        data: data as any
      }))
  },
  updateMany: async () => ({
    data: [] as any
  }),
  delete: async () => ({
    data: [] as any
  }),
  deleteMany: async () => ({
    data: [] as any
  })
}

import { Datagrid, FunctionField, List, SelectArrayInput, TextField, TextInput } from 'react-admin'
import { type User } from '@entities/user/types/user'
import { UserRole } from '@shared/types/userRole'

const filters = [
  <TextInput key="email" label="Email" source="email" alwaysOn />,
  <SelectArrayInput
    key="roles"
    source="roles"
    choices={Object.values(UserRole).map((role) => ({
      id: role,
      name: role
    }))}
    variant="outlined"
  />
]

export const UserList = () => {
  return (
    <List filters={filters} sort={{ field: 'id', order: 'DESC' }} exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="username" sortable={false} />
        <FunctionField label="Roles" render={(record: User) => record.roles?.join(', ')} />
        <FunctionField label="Create Date" render={(record: User) => new Date(record.createdAt).toLocaleDateString()} />
      </Datagrid>
    </List>
  )
}

import { defaultDarkTheme, defaultTheme } from 'react-admin'

export const theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#823CDC'
    },
    secondary: {
      main: '#FE8B21'
    }
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        ...defaultTheme.components?.MuiTextField?.defaultProps,
        variant: 'outlined'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined'
      }
    }
  }
}

export const darkTheme = {
  ...defaultDarkTheme,
  palette: {
    ...defaultDarkTheme.palette,
    primary: {
      main: '#FE8B21'
    },
    secondary: {
      main: '#823CDC'
    }
  },
  components: {
    ...defaultDarkTheme.components,
    MuiTextField: {
      defaultProps: {
        ...defaultDarkTheme.components?.MuiTextField?.defaultProps,
        variant: 'outlined'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined'
      }
    }
  }
}

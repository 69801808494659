export default function getDiscount (baseProduct, priceItem, amount) {
  if (!baseProduct) return null

  const price = priceItem.price
  const basePriceItem = baseProduct?._embedded?.prices?.find((el) => el.country === priceItem.country && el.currency === priceItem.currency)
  const basePrice = (basePriceItem?.price / baseProduct.amount) * amount
  const discountValue = Math.round(100 - ((price / basePrice) * 100))

  return `${-discountValue}%`
}

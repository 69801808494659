import { Admin, Resource } from 'react-admin'
import { useEffect } from 'react'
import wss from '@shared/wss/client-wss'
import { dataProvider } from './dataProvider'
import { authProvider } from './authProvider'
import { theme, darkTheme } from './themes'
import { UserRole } from '@shared/types/userRole'
import { Layout } from '@widgets/layout/Layout'
import { CustomMenu } from '@widgets/menu/Menu'

import { Login } from '@pages/login/Login'
import user from '@pages/user'
import student from '@pages/student'
import company from '@pages/company'
import teacher from '@pages/teacher'
// import product from '@pages/product'
// import promocode from '@pages/promocode'

import PersonIcon from '@mui/icons-material/Person'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import Face4Icon from '@mui/icons-material/Face4'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
// import AddCardIcon from '@mui/icons-material/AddCard'

export const App = () => {
  useEffect(() => {
    wss.init()

    return () => {
      wss.close()
    }
  }, [])

  return (
    <Admin
      layout={Layout}
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
      darkTheme={darkTheme}
      loginPage={Login}
      requireAuth
      menu={CustomMenu}
    >
      {(permissions: UserRole[]) => (
        <>
          <Resource
            name="user"
            list={user.list}
            edit={permissions.includes(UserRole.ROLE_ADMIN) ? user.edit : undefined}
            create={permissions.includes(UserRole.ROLE_ADMIN) ? user.create : undefined}
            icon={PersonIcon}
          />
          <Resource
            name="student"
            list={student.list}
            show={student.show}
            edit={student.edit}
            icon={AccountBoxIcon}
          />
          <Resource
            name="teacher"
            list={teacher.list}
            show={teacher.show}
            edit={teacher.edit}
            create={teacher.create}
            icon={Face4Icon}
          />
          <Resource
            name="company"
            options={{ label: 'B2B Companies' }}
            list={company.list}
            show={company.show}
            edit={company.edit}
            create={company.create}
            icon={PeopleAltIcon}
          />
          {/* <Resource
            name="product"
            options={{ label: 'Product Management' }}
            list={product.list}
            show={product.show}
            edit={permissions.includes(UserRole.ROLE_MANAGER) ? undefined : product.edit}
            icon={ManageAccountsIcon}
          />
          <Resource
            name="promocode"
            options={{ label: 'Sales and Marketing' }}
            list={promocode.list}
            show={promocode.show}
            create={permissions.includes(UserRole.ROLE_MANAGER) ? undefined : promocode.create}
            edit={permissions.includes(UserRole.ROLE_MANAGER) ? undefined : promocode.edit}
            icon={AddCardIcon}
          /> */}
        </>
      )}
    </Admin>
  )
}

import { type AuthProvider } from 'react-admin'
import { siteAuth } from '@shared/auth/api'
import utils from '@shared/auth/utils'
import wss from '@shared/wss/client-wss'
import { ADMIN_ROLES, type UserRole } from '@shared/types/userRole'

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    try {
      const { data } = await siteAuth({ username, password })

      utils.saveToken(data.tokenAccess)

      const userData = utils.convertToken(data.tokenAccess.access_token)

      if (
        !(userData.roles as UserRole[]).some(
          userRole => ADMIN_ROLES.includes(userRole)
        )
      ) {
        throw new Error('Access denied')
      }

      localStorage.setItem('user', JSON.stringify({
        id: userData.uid,
        username,
        roles: userData.roles
      }))

      wss.init()
    } catch (err: any) {
      if (Object.prototype.hasOwnProperty.call(err, 'graphQLErrors')) {
        throw new Error(err.graphQLErrors[0].error_description)
      } else throw err
    }
  },
  logout: async () => {
    localStorage.removeItem('user')
  },
  checkError: async (error) => {
    if (error.message === 'refresh_token_invalid') {
      localStorage.removeItem('user')
    }
  },
  checkAuth: async () => {
    if (localStorage.getItem('user') === null || utils.getRefreshToken() === '') throw new Error()
  },
  getPermissions: async () => {
    const persistedUser = localStorage.getItem('user')
    const user = persistedUser !== null ? JSON.parse(persistedUser) : null

    return user.roles
  },
  getIdentity: async () => {
    const persistedUser = localStorage.getItem('user')
    const user = persistedUser !== null ? JSON.parse(persistedUser) : null

    return await Promise.resolve(user)
  }
}

export default authProvider

import { useState } from 'react'

export function useCopyButton<T> (label: string, clickHandler: (args: T) => any) {
  const [currentLabel, setCurrentLabel] = useState(label)

  const onClick = (args: T) => {
    setCurrentLabel('Copied!')

    setTimeout(() => {
      setCurrentLabel(label)
    }, 3000)

    clickHandler(args)
  }

  return [
    currentLabel,
    onClick
  ] as const
}

import { Grid, Typography } from '@mui/material'
import { SimpleForm, TextInput, email, AutocompleteInput, required } from 'react-admin'
import { DefaultToolbar } from '@widgets/default-toolbar/DefaultToolbar'
import { phone } from '@shared/validators/phone'
import { LanguageCode } from '@shared/types/language'
import { CountryCode } from '@shared/types/country'

export const CompanyEditForm = () => (
  <SimpleForm toolbar={<DefaultToolbar />}>
    <Typography variant="h6" marginBottom={1}>
      Company details
    </Typography>
    <Grid container spacing={2}>
      <Grid item md={8}>
        <TextInput label="Company name" source="title" fullWidth validate={[required()]} />
      </Grid>
      <Grid item md={4}>
        <TextInput source="contract" fullWidth />
      </Grid>
    </Grid>

    <Typography variant="h6" marginTop={2} marginBottom={1}>
      Contact details
    </Typography>
    <Grid container spacing={2}>
      <Grid item md={4}>
        <TextInput source="contactPerson" fullWidth validate={[required()]} />
      </Grid>
      <Grid item md={4}>
        <TextInput source="email" validate={[required(), email()]} fullWidth />
      </Grid>
      <Grid item md={4}>
        <TextInput source="phone" fullWidth validate={[required(), phone()]} />
      </Grid>
    </Grid>

    <Typography variant="h6" marginTop={2} marginBottom={1}>
      Notes about company
    </Typography>
    <Grid container spacing={2}>
      <Grid item md={12}>
        <TextInput source="notes" fullWidth multiline />
      </Grid>
    </Grid>

    <Typography variant="h6" marginTop={2} marginBottom={1}>
      Locale
    </Typography>
    <Grid container spacing={2}>
      <Grid item md={6}>
        <AutocompleteInput
          source="country"
          choices={Object.entries(CountryCode).map(([name, id]) => ({
            id,
            name
          }))}
          fullWidth
          validate={[required()]}
        />
      </Grid>
      <Grid item md={6}>
        <AutocompleteInput
          source="languageCommunication"
          label="Language"
          choices={Object.entries(LanguageCode).map(([name, id]) => ({
            id,
            name
          }))}
          fullWidth
          validate={[required()]}
        />
      </Grid>
    </Grid>
  </SimpleForm>
)

import { CompanyList } from './CompanyList'
import { CompanyShow } from './CompanyShow'
import { CompanyEdit } from './CompanyEdit'
import { CompanyCreate } from './CompanyCreate'

export default {
  list: CompanyList,
  show: CompanyShow,
  edit: CompanyEdit,
  create: CompanyCreate
}

import { PromocodeList } from './PromocodeList'
import { PromocodeCreate } from './PromocodeCreate'
import { PromocodeEdit } from './PromocodeEdit'
import { PromocodeShow } from './PromocodeShow'

export default {
  list: PromocodeList,
  create: PromocodeCreate,
  edit: PromocodeEdit,
  show: PromocodeShow
}

import { FunctionField, Link, Show, TabbedShowLayout, TextField, useShowContext } from 'react-admin'
import { useEffect, useState } from 'react'
import { Alert, Avatar, Box, Chip, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { CountTheLessons } from '@features/count-the-lessons/CountTheLessons'
import { QuickLessonLink } from '@features/quick-lesson-link/QuickLessonLink'
import wss from '@shared/wss/client-wss'

const StudentShowLayout = () => {
  const { record, isLoading } = useShowContext()

  const [isLessonInProgress, setIsLessonInProgress] = useState({})

  const formatSchedule = (studentList) => {
    if (!studentList?.trial) return studentList.schedule

    const date = new Date(studentList.schedule).toLocaleString()

    return date !== 'Invalid Date' ? date : studentList.schedule
  }

  const setLessonStatus = (data) => {
    const sessions = {}

    Object.keys(data.sessions || {}).forEach((el) => {
      sessions[el] = data.sessions[el]?.length > 1
    })

    setIsLessonInProgress(sessions)
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  const getLessonLink = (key) => `https://${window.location.host.replace('admin-', '').replace('admin.', '')}/class/${record.studentLists?.[key]?.session?.id}/${record.studentLists?.[key]?.session?.course?.id}`

  const isRegistrationNotCompleted = (record?.user?.roles?.length ?? 0) < 2

  useEffect(() => {
    if (record?.studentLists && record.id) {
      wss.publish({
        cb: {
          action: setLessonStatus,
          id: record.id
        },
        data: {
          action: 'admin-connection-check-request',
          cbId: record.id,
          ids: (record.studentLists || []).map((el) => el?.session?.id).filter((el: string) => el)
        }
      })
    }

    return () => {}
  }, [record])

  if (isLoading) return

  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid item md={2}>
          <Avatar sx={{ aspectRatio: '1 / 1', width: '100%', height: 'auto' }} src={record.user?.avatar?.at(-1)?.path} variant="square" />
        </Grid>
        <Grid item md={10}>
          <Stack>
            <Typography variant="h6" marginBottom={2}>
              {record.name}
              <Chip label={record.company ? 'B2B' : 'B2C'} sx={{ marginLeft: 1 }} />
              {record.company ? <Chip label={record.company.title} sx={{ marginLeft: 1 }} variant="outlined" /> : null}
              {isRegistrationNotCompleted ? (<Chip label="Registration is not completed" sx={{ marginLeft: 1 }} variant="outlined" />) : null}
            </Typography>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">ID</Typography>
                  <TextField source="id" />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Country</Typography>
                  <TextField source="country" />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Lessons</Typography>
                  <TextField source="balance" />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Email</Typography>
                  <TextField source="user.username" />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Language</Typography>
                  <TextField source="languageCommunication" />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Paid lessons</Typography>
                  <FunctionField label="Paid lessons" render={
                    (record) => record.lessonsLog
                      .filter((item) => item.type === 'payment')
                      .reduce((acc: number, item) => acc + (item.balance as number), 0)
                    } />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2} marginBottom={2}>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Phone</Typography>
                  <TextField source="phone" />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Create Date</Typography>
                  <FunctionField label="Create Date" render={(record) => new Date(record.createdAt).toLocaleDateString()} />
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack>
                  <Typography variant="caption">Level</Typography>
                  <TextField source="englishLevel.title" />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={4}></Grid>
              <Grid item md={4}></Grid>
              <Grid item md={4}>
                <Stack spacing={2}>
                  <CountTheLessons />
                  <QuickLessonLink isRegistrationNotCompleted={isRegistrationNotCompleted} />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{ marginTop: 4 }} />

      {record.studentLists?.length > 0
        ? (
          <TableContainer sx={{ marginTop: 4 }}>
            <Table>
              <TableBody>
                {record.studentLists?.map((studentList, key) => (
                  <TableRow
                    key={studentList.id}
                    sx={{ 'td, th': { border: 0 } }}
                  >
                    <TableCell sx={{ paddingLeft: 0, verticalAlign: 'top' }}>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Avatar sx={{ width: '64px', height: '64px' }} src={studentList?.teacher?.user?.avatar?.at(-1)?.path} />
                        {studentList.teacher
                          ? (
                          <Link to={`/teacher/${studentList?.teacher?.id as number}/show`} sx={{ color: 'currentColor' }}>
                            <Stack>
                              <Typography variant="caption">Teacher</Typography>
                              <Typography variant="body1">{studentList?.teacher?.name}</Typography>
                              <Typography variant="body2">{studentList?.teacher?.languages?.join(', ')}</Typography>
                            </Stack>
                          </Link>
                            )
                          : null}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <Stack>
                        <Typography variant="caption">Course</Typography>
                        <Typography variant="body1">{studentList?.session?.course?.titleEn}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <Stack>
                        <Typography variant="caption">Date last less</Typography>
                        <Typography variant="body1">{studentList?.session?.lastLessonDate ? new Date(studentList?.session?.lastLessonDate).toLocaleDateString() : null}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <Stack>
                        <Typography variant="caption">Lesson status</Typography>
                        {isLessonInProgress[studentList?.session?.id]
                          ? (
                          <Alert severity="success">
                            In progress. <a href={getLessonLink(key)} target="_blank" rel="noreferrer">Open</a>
                          </Alert>
                            )
                          : (
                          <Alert severity="error">Not in progress</Alert>
                            )}
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <Stack>
                        <Typography variant="caption">Completed lessons</Typography>
                        <Typography variant="body1">{studentList?.session?.lessonsFinishedCount}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <Stack>
                        <Typography variant="caption">Schedule</Typography>
                        <Typography variant="body1">{formatSchedule(studentList)}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )
        : null}

      <TabbedShowLayout sx={{ marginTop: 4 }}>
        <TabbedShowLayout.Tab label="Notes">
          <Typography variant="h6">Notes about the student</Typography>
          <Box sx={{ borderRadius: 1, border: 1, borderColor: 'grey.600', padding: 1 }}>
            <TextField source="notes" />
          </Box>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Lesson history">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Create Date</TableCell>
                  <TableCell>Transaction type</TableCell>
                  <TableCell>Lessons</TableCell>
                  <TableCell>Responsible</TableCell>
                  <TableCell>Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {record.lessonsLog?.map((item) => (
                  <TableRow key={item.createdAt}>
                    <TableCell>
                      {new Date(item.createdAt).toLocaleDateString()} {new Date(item.createdAt).toLocaleTimeString()}
                    </TableCell>
                    <TableCell>{item.type}</TableCell>
                    <TableCell>{item.balance}</TableCell>
                    <TableCell>{item.reporter?.username}</TableCell>
                    <TableCell>{item.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Box>
  )
}

export const StudentShow = () => (
  <Show>
    <StudentShowLayout />
  </Show>
)

import { gql } from '@apollo/client'
import { type DataProvider } from 'react-admin'
import { apolloClient } from '@shared/api/apolloClient'
import { formatDateTime } from './utils/formatDateTime'

const PROMOCODE_FIELDS = `
  id
  promo_code
  amount
  type
  status
  title
  description
  payment_type
  user_id
  packages
  dt_created {
    date
  }
  start_date {
    date
  }
  finish_date {
    date
  }
  activations
`

const PROMOCODE_RESPONSE_FIELDS = `
  _total_items
  _page
  _page_count
  data {
    ${PROMOCODE_FIELDS}
  }
`

export const promocodeProvider: DataProvider = {
  getList: async (resource, { pagination, filter, sort }) => {
    return await apolloClient
      .query({
        query: gql`
          query($page: Int, $limit: Int, $promocode: String,) {
            getPromocodes(page: $page, limit: $limit, promocode: $promocode) {
              ${PROMOCODE_RESPONSE_FIELDS}
            }
          }
        `,
        variables: {
          promocode: filter.promocode,
          page: pagination.page,
          limit: pagination.perPage
        }
      })
      .then((result) => ({
        data: result.data.getPromocodes?.data ?? [],
        pageInfo: {
          hasNextPage: result.data.getPromocodes?._page < result.data.getPromocodes?._page_count,
          hasPreviousPage: result.data.getPromocodes?._page > 1
        }
      }))
  },
  getOne: async (resource, { id }) => {
    return await apolloClient
      .query({
        query: gql`
          query($id: Int) {
            getPromocode(id: $id) {
              ${PROMOCODE_FIELDS}
            }
          }
        `,
        variables: {
          id: +id
        }
      })
      .then((result) => ({
        data: result.data.getPromocode
      }))
  },
  getMany: async () => ({
    data: [] as any
  }),
  getManyReference: async () => ({
    data: [] as any,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false
    }
  }),
  create: async (resource, { data }) => {
    return await apolloClient
      .mutate({
        mutation: gql`
          mutation ($promocodeData: PromocodeCreateInput) {
            createPromocode(promocodeData: $promocodeData) {
              ${PROMOCODE_FIELDS}
            }
          }
        `,
        variables: {
          promocodeData: {
            promo_code: data.promo_code,
            amount: data.amount,
            type: data.type,
            status: data.status,
            start_date: data.start_date?.date ? formatDateTime(data.start_date.date) : null,
            finish_date: data.finish_date?.date ? formatDateTime(data.finish_date.date) : null,
            payment_type: data.payment_type,
            activations: data.activations,
            description: data.description,
            packages: data.packages
          }
        }
      })
      .then((result) => ({
        data: result.data.createPromocode
      }))
  },
  update: async (resource, { id, data }) => {
    return await apolloClient
      .mutate({
        mutation: gql`
          mutation ($id: Int, $promocodeData: PromocodeUpdateInput) {
            updatePromocode(id: $id, promocodeData: $promocodeData) {
              ${PROMOCODE_FIELDS}
            }
          }
        `,
        variables: {
          id: +id,
          promocodeData: {
            status: data.status,
            start_date: data.start_date?.date ? formatDateTime(data.start_date.date) : null,
            finish_date: data.finish_date?.date ? formatDateTime(data.finish_date.date) : null,
            activations: data.activations,
            description: data.description
          }
        }
      })
      .then((result) => ({
        data: result.data.updatePromocode
      }))
  },
  updateMany: async () => ({
    data: [] as any
  }),
  delete: async () => ({
    data: [] as any
  }),
  deleteMany: async () => ({
    data: [] as any
  })
}

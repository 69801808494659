import { Avatar, Box, Chip, Divider, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { FunctionField, Link, Show, TextField, useShowContext } from 'react-admin'
import { getLastLessonDate } from '@shared/helpers/getLastLessonDate'
import { CompanyRegistrationLink } from '@features/company-registration-link/CompanyRegistrationLink'

const CompanyShowLayout = () => {
  const { record, isLoading } = useShowContext()

  if (isLoading) return

  return (
    <Box padding={2}>
      <Box>
        <Typography variant="h6" marginBottom={1}>
          {record.title}
          {record.country ? <Chip label={record.country} sx={{ marginLeft: 1 }} /> : null}
        </Typography>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item md={4}>
            <Stack>
              <Typography variant="caption">ID</Typography>
              <TextField source="id" />
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack>
              <Typography variant="caption">Contract</Typography>
              <TextField source="contract" />
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack>
              <Typography variant="caption">email</Typography>
              <TextField source="email" />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item md={4}>
            <Stack>
              <Typography variant="caption">Contact person</Typography>
              <TextField source="contactPerson" />
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack>
              <Typography variant="caption">Communication language</Typography>
              <TextField source="languageCommunication" />
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack>
              <Typography variant="caption">phone</Typography>
              <TextField source="phone" />
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Stack>
              <Typography variant="caption">Balance</Typography>
              <Box>{record?.employees?.reduce((acc: number, employee) => { return acc + (employee?.balance as number) }, 0) || 0}</Box>
            </Stack>
          </Grid>
          <Grid item md={4}>
            <Stack>
              <Typography variant="caption">Create Date</Typography>
              <FunctionField render={(record) => new Date(record.createdAt).toLocaleDateString()} />
            </Stack>
          </Grid>
          <Grid item md={4}>
            <CompanyRegistrationLink id={record.id} />
          </Grid>
        </Grid>
      </Box>

      {record.notes
        ? (
        <>
          <Divider sx={{ marginTop: 4 }} />
          <Stack marginTop={4}>
            <Typography variant="h6" marginBottom={1}>
              Notes about company
            </Typography>
            <Box sx={{ borderRadius: 1, border: 1, borderColor: 'grey.600', padding: 1 }}>
              <TextField source="notes" />
            </Box>
          </Stack>
        </>
          )
        : null}

      <Divider sx={{ marginTop: 4 }} />

      <Stack marginTop={4}>
        <Typography variant="h6" marginBottom={1}>
          {record?.employees?.length ?? 0} employees
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Date last lesson</TableCell>
                <TableCell>Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {record?.employees?.sort((a, b) => b.id - a.id).map((employee) => (
                <TableRow key={employee.id}>
                  <TableCell component="th" scope="row">
                    <Link to={`/student/${employee.id as number}/show`} sx={{ color: 'currentColor' }}>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Avatar src={employee.user?.avatar?.[0]?.path}>{employee.name?.[0]}</Avatar>
                        <Box>
                          <Typography variant="body1">{employee.name}</Typography>
                          <Typography variant="body2">ID {employee.id}</Typography>
                        </Box>
                      </Box>
                    </Link>
                  </TableCell>
                  <TableCell>{employee.user?.username}</TableCell>
                  <TableCell>{employee.phone}</TableCell>
                  <TableCell>{getLastLessonDate(employee)}</TableCell>
                  <TableCell>{employee.balance}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  )
}

export const CompanyShow = () => (
  <Show>
    <CompanyShowLayout />
  </Show>
)

import { Datagrid, FunctionField, List, AutocompleteArrayInput, TextField, TextInput, minLength } from 'react-admin'
import { getLastLessonDate } from '@shared/helpers/getLastLessonDate'
import { CountryCode } from '@shared/types/country'

const filters = [
  <TextInput key="id" source="id" alwaysOn />,
  <TextInput key="email" label="Email" source="username" alwaysOn />,
  <TextInput key="name" source="name" validate={[minLength(4)]} alwaysOn />,
  <TextInput key="phone" source="phone" />,
  <AutocompleteArrayInput
    key="country"
    label="Country"
    source="country"
    choices={Object.entries(CountryCode).map(([name, id]) => ({
      id,
      name
    }))}
  />
]

export const StudentList = () => {
  return (
    <List filters={filters} sort={{ field: 'id', order: 'DESC' }} exporter={false}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <TextField label="Email" source="user.username" sortable={false} />
        <TextField source="phone" sortable={false} />
        <TextField label="Company" source="company.title" sortable={false} />
        <TextField source="country" sortable={false} />
        <FunctionField label="Courses" render={(record) => record.studentLists?.map(i => i.session.course.titleEn).join(', ')} />
        <TextField source="balance" sortable={false} />
        <FunctionField label="Create Date" render={(record) => new Date(record.createdAt).toLocaleDateString()} />
        <FunctionField label="Date last lesson" render={getLastLessonDate} />
      </Datagrid>
    </List>
  )
}

import { Chip } from '@mui/material'
import { Datagrid, FunctionField, List, ReferenceArrayField, SingleFieldList, TextField, TextInput, minLength } from 'react-admin'

const filters = [
  <TextInput key="promocode" source="promocode" validate={[minLength(4)]} alwaysOn />
]

export const PromocodeList = () => {
  return (
    <List filters={filters} exporter={false}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" sortable={false} />
        <TextField source="promo_code" sortable={false} />
        <TextField source="type" sortable={false} />

        <TextField source="amount" label="Discount / Bonus" sortable={false} />

        <ReferenceArrayField label="Product (lesson pack)" reference="product" source="packages" sortable={false}>
          <SingleFieldList>
            <FunctionField
              render={
                (record: { title: string, amount: string }) => (<Chip sx={{ marginRight: '8px' }} label={`${record.title} (${record.amount})`} />)
              }
            />
          </SingleFieldList>
        </ReferenceArrayField>

        {/* <TextField source="country" sortable={false} /> */}

        <TextField source="payment_type" label="Valid for students" sortable={false} />
        <FunctionField label="Start date" render={
          (record) => record.start_date?.date ? new Date(record.start_date.date).toLocaleString() : null
        } />
        <FunctionField label="End date" render={
          (record) => record.finish_date?.date ? new Date(record.finish_date.date).toLocaleString() : null
        } />
      </Datagrid>
    </List>
  )
}

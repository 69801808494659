import { SimpleForm, TextInput, DateInput, email, AutocompleteInput, required, SelectInput, AutocompleteArrayInput, Create, ReferenceArrayInput, SelectArrayInput, BooleanInput, NumberInput, PasswordInput } from 'react-admin'
import { Grid, Typography } from '@mui/material'
import { DefaultToolbar } from '@widgets/default-toolbar/DefaultToolbar'
import { phone } from '@shared/validators/phone'
import { CountryCode } from '@shared/types/country'
import { LanguageCode } from '@shared/types/language'
import { TeacherGrade } from '@entities/teacher/types/teacherGrade'

export const TeacherCreate = () => (
  <Create redirect="show">
    <SimpleForm toolbar={<DefaultToolbar />}>
      <Typography variant="h6" marginBottom={1}>
        User
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextInput label="Email" source="username" validate={[required(), email()]} fullWidth />
        </Grid>
        <Grid item md={6}>
          <PasswordInput source="password" validate={[required()]} fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" marginTop={2} marginBottom={1}>
        Identity
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <TextInput source="name" label="First name" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <TextInput source="lastName" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={4} alignSelf="center">
          <TextInput source="phone" validate={[required(), phone()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <DateInput label="Date of birth" source="birthday" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <AutocompleteInput
            source="country"
            choices={Object.entries(CountryCode).map(([name, id]) => ({
              id,
              name
            }))}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={4}>
          <AutocompleteArrayInput
            source="languages"
            choices={Object.entries(LanguageCode).map(([name, id]) => ({
              id,
              name
            }))}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={4} alignSelf="center">
          <BooleanInput source="nativeSpeaker" fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" marginTop={2} marginBottom={1}>
        Education
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <TextInput label="Education and certificate" source="education" validate={[required()]} fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" marginBottom={1}>
        Courses
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <ReferenceArrayInput source="course" reference="course">
            <SelectArrayInput optionText="titleEn" validate={[required()]} fullWidth variant="outlined" />
          </ReferenceArrayInput>
        </Grid>
        <Grid item md={4}>
          <ReferenceArrayInput source="levelStudent" reference="englishLevel">
            <SelectArrayInput label="Level students" optionText="title" validate={[required()]} fullWidth variant="outlined" />
          </ReferenceArrayInput>
        </Grid>
        <Grid item md={4}>
          <NumberInput source="experience" validate={[required()]} fullWidth />
        </Grid>
        <Grid item md={4}>
          <SelectInput
            source="grade"
            choices={Object.values(TeacherGrade).map((role) => ({
              id: role,
              name: role
            }))}
            validate={[required()]}
            fullWidth
          />
        </Grid>
        <Grid item md={4} alignSelf="center">
          <BooleanInput source="methodist" fullWidth />
        </Grid>
      </Grid>

      <Typography variant="h6" marginTop={2} marginBottom={1}>
        Notes
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <TextInput source="notes" fullWidth multiline />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
)

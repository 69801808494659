import { gql } from '@apollo/client'
import { apolloClient } from '@shared/api/apolloClient'
import { type PaymentType } from '@shared/types/paymentType'

export const addStudentBalanceLog = async (data: { id: number, lessonsCount: number, type: PaymentType, description: string }) => await apolloClient.mutate({
  mutation: gql`
      mutation($studentId: Int, $balance: Int, $type: PaymentTypes, $description: String) {
        addStudentBalanceLog(studentId: $studentId, balance: $balance, type: $type, description: $description) {
          status
        }
      }
    `,
  variables: {
    studentId: data.id,
    balance: data.lessonsCount,
    type: data.type,
    description: data.description
  }
})

import { AppBar as DefaultAppBar, RefreshIconButton, ToggleThemeButton } from 'react-admin'

export const AppBar = () => (
  <DefaultAppBar
    color="transparent"
    elevation={0}
    toolbar={
      <>
        <ToggleThemeButton />
        <RefreshIconButton />
      </>
    }
  />
)

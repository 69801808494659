export function getLastLessonDate (record) {
  if (!record.studentLists) return

  const lastLessonDates = record.studentLists
    .filter((i) => !!i.session.lastLessonDate)
    .map((i) => new Date(i.session.lastLessonDate))

  const isHasLastLessonDate = lastLessonDates.length > 0

  if (isHasLastLessonDate) {
    return new Date(Math.max(...lastLessonDates)).toLocaleDateString()
  }
}

import { Button } from '@mui/material'
import { encodeInviteLink } from './utils/encode-invite-link'

import { useCopyButton } from '@shared/hooks/useCopyButton'

export type CompanyRegistrationLinkProps = {
  id: number
}

export const CompanyRegistrationLink = ({ id }: CompanyRegistrationLinkProps) => {
  const [copyRegistrationLinkLabel, copyRegistrationLink] = useCopyButton(
    'Registration link',
    () => {
      void navigator.clipboard.writeText(encodeInviteLink(id))
    }
  )

  return (
    <Button variant="outlined" onClick={copyRegistrationLink}>
      {copyRegistrationLinkLabel}
    </Button>
  )
}

import { gql } from '@apollo/client'
import { apolloClient } from '@shared/api/apolloClient'
import utils from './utils'

export const siteAuth = async (data: {
  username: string
  password: string
}) => await apolloClient
  .mutate({
    mutation: gql`
      mutation TokenAccess(
        $username: String,
        $password: String,
      ) {
        tokenAccess(
          username: $username,
          password: $password,
        ) {
          access_token
          refresh_token
          status
        }
      }
    `,
    variables: {
      username: data.username,
      password: data.password
    }
  })

export const siteAuthRefresh = async () => await apolloClient
  .mutate({
    mutation: gql`
      mutation Token(
        $refreshToken: String,
      ) {
        tokenRefresh(
          refreshToken: $refreshToken,
        ) {
          access_token
          refresh_token
          status
        }
      }
    `,
    variables: {
      refreshToken: utils.getRefreshToken()
    }
  })

import Cookies from 'js-cookie'

const createClientId = () => {
  const rand = () => Math.random().toString(36).substr(2) // remove `0.`

  return rand() + rand() // to make it longer
}

const init = () => {
  if (Cookies.get('client_id') === undefined) {
    Cookies.set('client_id', createClientId(), { expires: 365 * 10, path: '/' })
  }
}

const get = () => Cookies.get('client_id')

export default {
  init,
  get
}
